import './App.css';

import Logo from './assets/Group 1Logo.svg'

function App() {


  const Share = () =>{
    window.open("https://www.instagram.com/maiarabarreto/","_blank")
  }



  return (
    <section className='container'>
      <article className='information'>
        <div className='Logo'>
          <img src={Logo} alt='Logo da empresa "Maiara Barreto Consultorias"' />
        </div>
        <div className='message'>
          <p className='medium'>
            Este site está em
          </p>
          <p className='big'>
            construção.
          </p>
          <p className='small'>
          Logo você poderá crescer com a gente
          </p>
          <p className='tiny paddingMessage'>
          Maiara Barreto - Consultoria e treinamentos.
          </p>
        </div>
        <div className='interage'>
          <button onClick={Share}>Acesse nossas redes sociais!</button>
        </div>
      </article>

      <article className='share'></article>
    </section>
  );
}

export default App;
